import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import LinkedinIcon from "../../../assets/linkedin-leadership.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const ResidentTeamSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          Norhart Property Management
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Norhart Resident Managers
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          The Norhart resident team is entirely focused on our valued residents. Our teams are constantly working to
          enhance and delight our residents while leveling up the customer experience end-to-end.
        </h4>

        <div className="container mt-5">
          <div className="row">
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-stacy-ekholm.png"
                      alt="Stacy Ekholm, Chief Operating Officer"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">Stacy Ekholm</span>
                  <div className="small m-t-xs"> Chief Operating Officer</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/stacy-ekholm-cpm-cos-crm-cmca-a587647b/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-luke-perry.png"
                      alt="Luke Perry, Regional Director"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">Luke Perry </span>
                  <div className="small m-t-xs">Regional Director</div>
                  <div className="small m-t-xs mt-4">
                    <a href="" target="_blank">
                      <a href="https://www.linkedin.com/in/luke-perry-962292172/" target="_blank">
                        <LinkedinIcon />
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-tory-kaupang.png"
                      alt="Tory Kaupang, Maintenace Manager"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">Tory Kaupang</span>
                  <div className="small m-t-xs">Maintenace Manager</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/tory-kaupang-18605120/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-keith-moeller.png"
                      alt="Keith Moeller, Facility Director"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">Keith Moeller</span>
                  <div className="small m-t-xs"> Maintenace Manager</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/keith-moeller-005b47192/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-blank.png"
                      alt="This Could Be You"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">This Could Be You</span>
                  <div className="small m-t-xs">New Title</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/tory-kaupang-18605120/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-1 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/resident-team/norhart-blank.png"
                      alt="This Could Be You"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Resident Team</small>
                  <span className="product-name">This Could Be You</span>
                  <div className="small m-t-xs">New Title</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/tory-kaupang-18605120/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
/** export */
export default ResidentTeamSection
